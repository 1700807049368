.app {
  height: 100vh;
  background-color: #add8e6; /* Diese Zeile setzt die Hintergrundfarbe auf Hellblau */
  background-image: url('./image.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
}


.countdown-text {
  padding: 4px;
  margin-left: 78px;
  margin-right: 78px;
  border-radius: 8px;
  text-align: center;
  color: white;
  font-weight: bold;
}

/* Mobile-Anpassungen */
@media (max-width: 768px) {
  .app {
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
